<template>
  <div id="main-wrapper">
    <div v-if="vpsPopup">
      <div class="mfp-bg animated zoomIn mfp-ready"></div>
      <div class="mfp-wrap mfp-close-btn-in mfp-auto-cursor animated zoomIn mfp-ready" tabindex="-1" style="overflow: hidden auto;">
        <div class="mfp-container mfp-s-ready mfp-inline-holder">
          <div class="mfp-content">
            <div id="upload-confirm" class="white-popup-block popup-content popup-small">
              <div class="content t-white t-center">
                <p>Are you sure you want to avail VPS {{vpsPopup}} Package ?</p>
              </div>
              <div class="btn-holder t-center">
                <router-link :to="'/support?title=vps&package='+vpsPopup.toLowerCase()" class="btn btn-yellow popup-link-holder">Yes</router-link>
                <a href="#" class="btn btn-lblue close-popup" @click.prevent="vpsPopup = ''">NO</a>
              </div>
              <button title="Close (Esc)" type="button" class="mfp-close" @click.prevent="vpsPopup = ''">×</button>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div
      class="content-container profile-page deposit-live transactions-dep auto-page copy-page"
    >
      <div class="page-wrap">
        <div class="page-content clearfix">
          <div class="main-content f-left">
            <div class="nav-holder">
              <ul>
                <li :key="t" v-for="t of tabs">
                  <a
                    @click="tab = t"
                    class="btn"
                    :class="tab == t ? 'btn-yellow' : 'btn-blue'"
                    >{{ t }}</a
                  >
                </li>
              </ul>
            </div>
            <Downloads v-if="tab == 'Downloads'" />
            <CopyTrading v-if="tab == 'Copy Trading'" />
            <VPS v-if="tab == 'VPS'" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CopyTrading from "./copytrading.vue";
import Downloads from "./downloads.vue";
import VPS from "./vps.vue";

export default {
  components: { CopyTrading, Downloads, VPS },
  data() {
    return {
      tabs: ["Downloads", "Copy Trading", "VPS"],
      tab: "Downloads",
      vpsPopup : ''
    };
  },
};
</script>
