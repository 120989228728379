<template>
  <div class="content-holder">
    <div class="title-holder">
      <h4>Copy Trading</h4>
      <p>Earn profits by automatically copying the strategy manager's trades</p>
    </div>
    <div class="auto-holder">
      <div class="left-col colu wow fadeInLeft" data-wow-delay="0.3s">
        <div class="auto-content">
          <img src="assets/images/copy1.png" alt="" />
          <h4>AUTOMATICALLY COPY LEADING TRADERS</h4>
        </div>
        <div class="auto-content">
          <img src="assets/images/copy2.png" alt="" />
          <h4>DIVERSIFIED TRADING PORTFOLIO</h4>
        </div>
        <div class="auto-content">
          <img src="assets/images/copy3.png" alt="" />
          <h4>MONITOR & PROFIT</h4>
        </div>
      </div>
      <div class="mid-col colu wow fadeIn" data-wow-delay="0.3s">
        <div class="auto-content listAlign">
          <h4>HOW TO COPY TRADES?</h4>
          <p><span>1.</span> Open a Live Account and make a deposit.</p>
          <p><span>2.</span> Choose a Strategy Manager.</p>
          <p><span>3.</span> Agree the terms and conditions.</p>
          <p><span>4.</span> Furnish us your agreement.</p>
          <p><span>5.</span> Earn profits by copying trades.</p>
        </div>
      </div>
      <div class="right-col colu wow fadeInRight" data-wow-delay="0.3s">
        <div class="auto-content">
          <img src="assets/images/copy4.png" alt="" />
          <h4>QUICK ORDER PROCESSING</h4>
        </div>
        <div class="auto-content">
          <img src="assets/images/copy5.png" alt="" />
          <h4>CONTROL OVER THE END RESULT</h4>
        </div>
        <div class="auto-content">
          <img src="assets/images/copy6.png" alt="" />
          <h4>UPGRADE YOUR TRADING SKILLS</h4>
        </div>
      </div>
    </div>
    <div class="btn-holder t-center">
      <a href="javascript:void(0)" class="btn btn-yellow popup-link-holder" @click.prevent="GoForCreateLiveAcc()">Start</a>
    </div>
  </div>
</template>
<script>
import { useMainStore } from "@/store";
export default {
  setup() {
    const store = useMainStore();
    return { store };
  },
  data() {
    return {
      
    };
  },
  methods: {
    GoForCreateLiveAcc(){
        this.$router.push({'name' : 'Create Account',params :{type: 'live', from : 'copy-trading' }})
    }
  },
};
</script>
