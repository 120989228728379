<template>
  <div class="content-holder">
    <div class="title-holder">
      <h4>VPS Plans</h4>
    </div>
    <div class="vps-plans wow fadeIn">
      <div class="plan-main bronze colu">
        <div class="box-shadow ease">
          <div class="title-holder">
            <h3>BRONZE</h3>
          </div>
          <div class="plan-holder ease">
            <ul>
              <li><p>Monthly Subscription $25</p></li>
              <li>
                <p>Get it free on deposit of $5,000 or above</p>
              </li>
              <li><p>Compatible with MT4, MT5 and Act</p></li>
              <li>
                <p>Free 25 GB Disk Space | 1300 MB RAM | 1 VCPU</p>
              </li>
              <li><p>Windows 8/10/12, Linux Supported</p></li>
            </ul>
          </div>
        </div>
        <div class="btn-holder">
          <!-- <router-link
            to="/support?title=vps&package=bronze"
            class="btn btn-blue popup-link-holder"
            >Avail Now</router-link
          > -->
          <a href="javascript:void(0)"  class="btn btn-blue popup-link-holder" @click.prevent="$parent.vpsPopup = 'BRONZE'">Avail Now</a>
        </div>
      </div>

      <div class="plan-main silver colu">
        <div class="box-shadow ease">
          <div class="title-holder">
            <h3>SILVER</h3>
          </div>
          <div class="plan-holder ease">
            <ul>
              <li><p>Monthly Subscription $45</p></li>
              <li>
                <p>Get it free on deposit of $10,000 or above</p>
              </li>
              <li><p>Compatible with MT4, MT5 and Act</p></li>
              <li>
                <p>Free 50 GB Disk Space | 2700 MB RAM | 2 VCPU</p>
              </li>
              <li><p>Windows 8/10/12, Linux Supported</p></li>
            </ul>
          </div>
        </div>
        <div class="btn-holder">
          <!-- <router-link
            to="/support?title=vps&package=silver"
            class="btn btn-blue popup-link-holder"
            >Avail Now</router-link
          > -->
          <a href="javascript:void(0)"  class="btn btn-blue popup-link-holder" @click.prevent="$parent.vpsPopup = 'SILVER'">Avail Now</a>
        </div>
      </div>
      <div class="plan-main gold colu">
        <div class="box-shadow ease">
          <div class="title-holder">
            <h3>GOLD</h3>
          </div>
          <div class="plan-holder ease">
            <ul>
              <li><p>Monthly Subscription $80</p></li>
              <li>
                <p>Get it free on deposit of $15,000 or above</p>
              </li>
              <li><p>Compatible with MT4, MT5 and Act</p></li>
              <li>
                <p>Free 75 GB Disk Space | 5120 MB RAM | 3 VCPU</p>
              </li>
              <li><p>Windows 8/10/12, Linux Supported</p></li>
            </ul>
          </div>
        </div>
        <div class="btn-holder">
          <!-- <router-link
            to="/support?title=vps&package=gold"
            class="btn btn-blue popup-link-holder"
            >Avail Now</router-link
          > -->
          <a href="javascript:void(0)"  class="btn btn-blue popup-link-holder" @click.prevent="$parent.vpsPopup = 'GOLD'">Avail Now</a>
        </div>
      </div>
    </div>
  </div>
</template>
