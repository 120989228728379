<template>
  <div class="content-holder">
    <div class="table-holder table-holder4 t-center">
      <table>
        <tr>
          <td><img src="assets/images/mt4-ico.png" alt="" /></td>
          <td>
            <a href="https://download.mql5.com/cdn/web/17274/mt4/aaafx4setup.exe" target="_blank">
              <img src="assets/images/windows-ico.png" alt="" />
              <p>MT4 Desktop</p>
            </a>
          </td>
          <td>
            <a href="https://play.google.com/store/apps/details?id=net.metaquotes.metatrader4&hl=en" target="_blank">
              <img src="assets/images/android-ico.png" alt="" />
              <p>MT4 Android</p>
            </a>
          </td>
          <td>
            <a href="https://apps.apple.com/us/app/metatrader-4/id496212596" target="_blank">
              <img src="assets/images/ios-ico.png" alt="" />
              <p>MT4 iOS</p>
            </a>
          </td>
          <td>
            <router-link to="/web-trader" target="_blank">
              <img src="assets/images/web-ico.png" alt="" />
              <p>MT4 Web Trader</p>
            </router-link>
          </td>
        </tr>
        <tr>
          <td><img src="assets/images/mt5-ico.png" alt="" /></td>
          <td>
            <a href="https://download.mql5.com/cdn/web/21263/mt5/aaafx5setup.exe" target="_blank">
              <img src="assets/images/windows-ico.png" alt="" />
              <p>MT5 Desktop</p>
            </a>
          </td>
          <td>
            <a href="https://download.mql5.com/cdn/mobile/mt5/android?server=AAAFx-5%20Real" target="_blank">
              <img src="assets/images/android-ico.png" alt="" />
              <p>MT5 Android</p>
            </a>
          </td>
          <td>
            <a href="https://download.mql5.com/cdn/mobile/mt5/ios?server=AAAFx-5%20Real" target="_blank">
              <img src="assets/images/ios-ico.png" alt="" />
              <p>MT5 iOS</p>
            </a>
          </td>
          <td>
            <router-link to="/web-trader" target="_blank">
              <img src="assets/images/web-ico.png" alt="" />
              <p>MT5 Web Trader</p>
            </router-link>
          </td>
        </tr>
        <!-- <tr>
          <td> <img src="assets/images/act-ico.png" alt=""> </td>
          <td>
            <a href="javascript:void(0)">
              <img src="assets/images/windows-ico.png" alt="">
              <p>Act Trader Desktop</p>
            </a>
          </td>
          <td>
            <a href="javascript:void(0)">
              <img src="assets/images/android-ico.png" alt="">
              <p>Act Trader Android</p>
            </a>
          </td>
          <td>
            <a href="javascript:void(0)">
              <img src="assets/images/ios-ico.png" alt="">
              <p>Act Trader iOS</p>
            </a>
          </td>
          <td>
            <a href="javascript:void(0)">
              <img src="assets/images/web-ico.png" alt="">
              <p>Act Web Trader</p>
            </a>
          </td>
        </tr> -->
        <!-- <tr>
          <td><img src="assets/images/zulu-ico.png" alt="" /></td>
          <td>
            <a href="javascript:void(0)">
              <p>&nbsp;</p>
            </a>
          </td>
          <td>
            <a href="javascript:void(0)">
              <p>&nbsp;</p>
            </a>
          </td>
          <td>
            <a href="javascript:void(0)">
              <p>&nbsp;</p>
            </a>
          </td>
          <td>
            <a href="javascript:void(0)">
              <img src="assets/images/web-ico.png" alt="" />
              <p>Zulu Web Trader</p>
            </a>
          </td>
        </tr> -->
      </table>
    </div>
  </div>
</template>
